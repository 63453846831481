import React from 'react';
import JuegoSnakepage from '../Projects/JuegoSnakePage';
import Footer2 from '../Footer2';

function JuegoSnake() {
    return (
        <>
            <JuegoSnakepage />
            <Footer2 />
        </>
    );
}

export default JuegoSnake;