import React from 'react';
import ChatAppReal from '../Projects/chatAppRealtimeReactPage';
import Footer2 from '../Footer2';

function ChatAppRealtime() {
    return (
        <>
            <ChatAppReal />
            <Footer2 />
        </>
    );
}

export default ChatAppRealtime;