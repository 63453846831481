import React from 'react';
import CrudFirebase from '../Projects/CrudfirebaseUsersPage';
import Footer2 from '../Footer2';

function CrudFirebaseUsers() {
    return (
        <>
            <CrudFirebase />
            <Footer2 />
        </>
    );
}

export default CrudFirebaseUsers;