import React from 'react';
import '../../App.css';
import Pruebapage from '../Pruebapage';
/* import PagPrincipal from '../PagPrincipal'; */

export default function Prueba() {
    return <>
    
    <Pruebapage />
    {/* <PagPrincipal /> */}
    
  </>
  }
