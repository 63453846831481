import React from 'react';
import ReactBuscador from '../Projects/ReactBuscadorImagenesPage';
import Footer2 from '../Footer2';

function ReactBuscadorImagenes() {
    return (
        <>
            <ReactBuscador />
            <Footer2 />
        </>
    );
}

export default ReactBuscadorImagenes;