import React from 'react';
import Footer2 from '../Footer2';

import DynoGame from '../Projects/DynoGamePage';

function DynoGameP() {
    return (
        <>
            <DynoGame />
            <Footer2 />
        </>
    );
}

export default DynoGameP;