import React from 'react';
import ReactFirebase from '../Projects/ReactFirebaseCrudPage';
import Footer2 from '../Footer2';

function ReactFirebaseCrud() {
    return (
        <>
            <ReactFirebase />
            <Footer2 />
        </>
    );
}

export default ReactFirebaseCrud;