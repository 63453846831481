import React from 'react';
import '../../App.css';
import CrudReact from '../Projects/FirebaseAngularCompleteCrudPage';
import Footer2 from '../Footer2';

function FirebaseAngularCompleteCrud() {
    return (
        <>
            <CrudReact />
            <Footer2 />
        </>
    );
}

export default FirebaseAngularCompleteCrud;