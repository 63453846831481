import React from 'react';
import '../../App.css';
import PagPrincipal from '../PagPrincipal';

export default function Products() {
  return (
  <>
  <PagPrincipal />
  </>
  );
}
