import React from 'react';
import WebECommercePAge from '../Projects/WebECommercePage';
import Footer2 from '../Footer2';

function WebECommerce() {
    return (
        <>
            <WebECommercePAge />
            <Footer2 />
        </>
    );
}

export default WebECommerce;