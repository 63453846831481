import React from 'react';
import '../../App.css';
import PagPrincipal from '../PagPrincipal';
import Footerprin from '../Footer';


function Home() {
  return (
    <>
      <PagPrincipal />
      <Footerprin />
    </>
  );
}

export default Home;
