import React from 'react';
import '../../App.css';
import PortfolioPag from '../PortfolioPag';
import Footer2 from '../Footer2';

export default function Sobremi() {
  return <>
  <PortfolioPag />
  <Footer2 />
  </>;
}
