import React from 'react';
import ReactAppClima from '../Projects/ReactAppClimaPage';
import Footer2 from '../Footer2';

function ReactClima() {
    return (
        <>
            <ReactAppClima />
            <Footer2 />
        </>
    );
}

export default ReactClima;