import React from 'react';
import ReactBuscadorCanciones from '../Projects/ReactBuscadorCancionesPage';
import Footer2 from '../Footer2';

function ReactBusCanciones() {
    return (
        <>
            <ReactBuscadorCanciones />
            <Footer2 />
        </>
    );
}

export default ReactBusCanciones;