import React from 'react';
import SpotiAppi from '../Projects/SpotiappApispotifyPage';
import Footer2 from '../Footer2';

function SpotiApp() {
    return (
        <>
            <SpotiAppi />
            <Footer2 />
        </>
    );
}

export default SpotiApp;